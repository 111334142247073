import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import Form from "../general/Form";
import { NutritionItem } from "../../logic/entities/NutritionItem";
import { useEffect, useState } from "react";
import { ExcludeId } from "../../logic/entities/ExcludeId";
import { deleteNutritionItem, getAllNutritionItems } from "../../logic/NutritionRepository";
import DeleteButton from "../general/DeleteButton";

interface Props {
  onSubmit: (content: NutritionItem | ExcludeId<NutritionItem>) => void;
  open: boolean;
  setClose: () => void;
  editItem?: NutritionItem;
  onDelete?: () => void;
}

export default (props: Props) => {
  const standardCategories = ['Lunch/Breakfast', 'Snacks', 'Diner', 'Drinks'];

  const isEditing = props.editItem !== undefined;

  const [categories, setCategories] = useState(standardCategories);

  useEffect(() => { fetchAllNutritionItems(); }, [props.open])

  async function fetchAllNutritionItems() {
    const nutritionItems = await getAllNutritionItems();
    const categories = nutritionItems
      .map(nutritionItem => nutritionItem.category)
      .filter(category => category);

    const uniqueCategories = (categories.length !== 0 ? categories : standardCategories)
      .filter((value, index, array) => array.indexOf(value) === index) as string[];

    setCategories(uniqueCategories);
  }

  async function confirmDelete() {
    if (!props.editItem) return;

    await deleteNutritionItem(props.editItem);
    props.onDelete?.();
    props.setClose();
  }

  function submitAddItemForm(formData: any) {
    const nutritionItemToAdd: ExcludeId<NutritionItem> = {
      ...props.editItem,
      name: formData.itemName,
      proteine: parseInt(formData.itemProteine),
      description: formData.itemDescription,
      calories: parseInt(formData.itemCalories),
    };

    nutritionItemToAdd.category = formData.itemCategory;

    props.setClose();
    props.onSubmit(nutritionItemToAdd);
  }

  return (
    <Dialog open={props.open} onClose={() => props.setClose()}>
      <FormControl error={true} required>
        <Form onSubmit={submitAddItemForm}>
          <DialogTitle sx={{ display: 'flex' }}>
            <Box sx={{ flexGrow: 1 }}>
              {isEditing ? 'Edit item' : 'New item'}
            </Box>
            {isEditing && <DeleteButton onConfirm={() => confirmDelete()}></DeleteButton>}
          </DialogTitle>
          <DialogContent>
            {!isEditing &&
              <DialogContentText>
                Add a food item to more easily add the nutrition value next time.
              </DialogContentText>
            }

            <Grid item xs={12}>
              <TextField
                defaultValue={props.editItem?.name}
                fullWidth
                required
                autoFocus
                label="Name"
                type="text"
                variant="standard"
                name="itemName"
              />
            </Grid>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <TextField
                  defaultValue={props.editItem?.proteine}
                  required
                  fullWidth
                  label="Proteine"
                  type="number"
                  variant="standard"
                  name="itemProteine"
                  inputProps={{ inputMode: "decimal" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  defaultValue={props.editItem?.calories}
                  required
                  fullWidth
                  label="Calories"
                  type="number"
                  variant="standard"
                  name="itemCalories"
                  inputProps={{ inputMode: "numeric" }}
                />
              </Grid>
            </Grid>


            <Autocomplete
              defaultValue={props.editItem?.category}
              sx={{ mt: 1 }}
              freeSolo
              options={categories}
              renderInput={(params) =>
                <TextField {...params}
                  name="itemCategory"
                  margin="dense"
                  variant="standard"
                  label="Category" />
              }
            />

            <TextField
              defaultValue={props.editItem?.description}
              margin="dense"
              label="Description of item"
              type="text"
              fullWidth
              variant="standard"
              name="itemDescription"
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setClose()}>Cancel</Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </Form>
      </FormControl>
    </Dialog>
  )
}