import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import Form from "../general/Form";
import { NutritionItem } from "../../logic/entities/NutritionItem";
import { ExcludeId } from "../../logic/entities/ExcludeId";
import { useState } from "react";
import { ConsumedItem } from "../../logic/entities/ConsumedItem";
import { getCurrentDayIndex } from "../../logic/Utils";

interface Props {
  onSubmit: (content: ExcludeId<ConsumedItem> | any) => void,
  open: boolean,
  setClose: () => void,
}

export default (props: Props) => {

  function submitAddItemForm(formData: any) {
    const consumedItem = {
      nutritionItem: {
        name: formData.itemName,
        proteine: parseInt(formData.itemProteine),
        calories: parseInt(formData.itemCalories),
        description: ''
      },
      day: getCurrentDayIndex(),
      timestamp: Date.now(),
    };

    props.setClose();
    props.onSubmit(consumedItem);
  }

  return (
    <Dialog open={props.open} onClose={() => props.setClose()}>
      <FormControl error={true} required>
        <Form onSubmit={submitAddItemForm}>
          <DialogTitle>Quick add</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Add a food item without saving it.
            </DialogContentText>

            <TextField
              required
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              name="itemName"
            />

            <Grid container spacing={2} sx={{ mt: 1 }}>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  label="Proteine"
                  type="number"
                  fullWidth
                  variant="standard"
                  name="itemProteine"
                  inputProps={{ inputMode: "decimal" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  margin="dense"
                  label="Calories"
                  type="number"
                  fullWidth
                  variant="standard"
                  name="itemCalories"
                  inputProps={{ inputMode: "decimal" }}
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setClose()}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </Form>
      </FormControl>
    </Dialog>
  )
}