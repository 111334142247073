// {props.children}
import { Link as RouterLink } from 'react-router-dom';

interface Props {
    to: string;
    children?: any;
}

export const Link = (props: Props) => {
    return <RouterLink
        to={props.to}
        style={{ textDecoration: 'none', color: 'inherit' }}>
        {props.children}
    </RouterLink>
}