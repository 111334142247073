import { Autocomplete, Box, Button, CircularProgress, Divider, Grid, Input, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Exercise } from '../../logic/entities/Exercise';
import { ScreenWrapper } from '../../ScreenWrapper';
import { WorkoutSet } from '../../components/WorkoutSet';
import { ExerciseSet } from '../../logic/entities/ExerciseSet';
import { PerformedWorkout } from '../../logic/entities/PerformedWorkout';
import { addPerformedWorkout, getAllExercises } from '../../logic/WorkoutRepository';

export default () => {
  const { workoutId } = useParams();

  const [currentExercise, setCurrentExercise] = useState<undefined | Exercise>(undefined);
  const [allExercises, setAllExercises] = useState<Exercise[]>([]);
  const [newRepsNum, setNewRepsNum] = useState('');
  const [newWeight, setNewWeight] = useState('');
  const [performingWorkout, setPerformingWorkout] = useState<PerformedWorkout>();
  const [creatingWorkout, setCreatingWorkout] = useState(false);

  useEffect(() => {
    getAllExercises()
      .then((exercises) => {
        setAllExercises(exercises);
      });
  }, []);

  useEffect(() => {
    setCreatingWorkout(true);
    if (performingWorkout || creatingWorkout) return;

    addPerformedWorkout({
      timestampStarted: Date.now(),
      sets: [],
      isCompleted: false,
    }).then(performingWorkout => {
      setPerformingWorkout(performingWorkout);
    });
  }, []);

  const currentSelectedExercise = currentExercise ? {
    label: currentExercise.name,
    id: currentExercise.id
  } : null;

  const exerciseLabelList = allExercises.map(
    exercise => ({
      label: exercise.name,
      id: exercise.id
    })
  );

  const addRep = () => {
    if (!currentExercise) return;

    const newSet: ExerciseSet = {
      exercise: currentExercise,
      weight: parseFloat(newWeight),
      reps: parseInt(newRepsNum),
    };

    // setCurrentSets(
    //   [...currentSets, newSet]
    // );

    performingWorkout?.sets.push(newSet);

    // const newWorkout: PerformedWorkout = {
    //   id: 0,
    //   timestampStarted: Date.now(),
    //   sets: [newSet],
    // };
    // console.log('addPerformedWorkout...')
    // addPerformedWorkout(newWorkout);
  }

  return <ScreenWrapper backText="Workouts">
    {currentExercise ? <h2>Doing exercise</h2> : <h2>Choose exercise:</h2>}

    <Autocomplete
      sx={{ mt: 1 }}
      size="small"
      id="currentExercise"
      options={exerciseLabelList}
      value={currentSelectedExercise}
      onChange={(_event, option) => { option && setCurrentExercise({ name: option.label, id: option.id }) }}
      renderInput={(params) =>
        <TextField {...params} label={currentExercise?.name ?? 'Choose...'}
        />
      }
    />

    <Box sx={{ mb: 2 }}>
      <Grid container spacing={1} sx={{ mt: 1, mb: 2 }}>
        <Grid item xs={6}>
          {/* <Item>xs=8</Item> */}
          <TextField variant="standard" label="Rep Weight"
            inputProps={{ inputMode: 'decimal', pattern: '[0-9]*' }}
            value={newWeight}
            onChange={e => setNewWeight(e.target.value)}></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField variant="standard" label="Number of reps"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            value={newRepsNum}
            onChange={e => setNewRepsNum(e.target.value)}></TextField>
        </Grid>
      </Grid>

      <Button variant="contained" onClick={e => addRep()}>Add</Button>

    </Box>

    <Divider />
    {performingWorkout?.sets.map(set => <WorkoutSet set={set}></WorkoutSet>)}

    {/* {!allExercises && <CircularProgress />}
        {allExercises && <ExerciseList exercises={allExercises}></ExerciseList>} */}

  </ScreenWrapper>
}
