import { Box, Button, Divider, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from '../components/navigation/Link';
import { ScreenWrapper } from '../ScreenWrapper';
import { getAllWorkoutSchemas } from '../logic/WorkoutRepository';

class WorkoutScreen extends React.Component {

  render() {

    getAllWorkoutSchemas();

    return <ScreenWrapper title='Your workouts'>
      <Grid container spacing={2}>
        <Grid item xs>
          <h2>Start workout</h2>
        </Grid>
        <Grid item>
          <Button variant="contained">Create new</Button>
        </Grid>
      </Grid>

      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <nav aria-label="main mailbox folders">
          <List>
            <Divider />
            <Link to="/workout/execute">
              <ListItem disablePadding secondaryAction={<ArrowForwardIosIcon />}>
                <ListItemButton>
                  <ListItemText primary="Start empty workout" secondary="Last done: never" />
                </ListItemButton>
              </ListItem>
            </Link>
            <Divider />
          </List>
        </nav>
      </Box>
    </ScreenWrapper>
  }
}

export default WorkoutScreen;