import { Collapse, Grid, ListItemButton } from "@mui/material";
import { Fragment, useState } from "react";

interface Props {
  content: (isOpen: boolean) => React.ReactNode;
  children?: any;
  defaultOpen: boolean;
}

export default (props: Props) => {

  const [isOpen, setOpen] = useState(props.defaultOpen);

  return <Fragment>
    <ListItemButton dense disableGutters sx={{ ml: 1 }}>
      <Grid item xs={12} onClick={() => setOpen(!isOpen)} style={{ verticalAlign: 'middle', lineHeight: '20px' }}>
        {props.content(isOpen)}
      </Grid>
    </ListItemButton>

    <Grid item xs={12}>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </Grid>

  </Fragment>
};