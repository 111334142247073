import { TABLES, addEntryFillKey, cacheQuery, deleteEntry, editEntry, invalidateTableCache } from "./Database";
import { ExcludeId } from "./entities/ExcludeId";
import { NutritionItem } from "./entities/NutritionItem";

export async function getAllNutritionItems(): Promise<NutritionItem[]> {
  return await cacheQuery<NutritionItem>('getAllNutritionItems', TABLES.NUTRITION_ITEM);
}

export async function getNutritionItem(id: number): Promise<NutritionItem> {
  const allItems = await getAllNutritionItems();
  const nutritionItem = allItems.find(nutritionItem => nutritionItem.id === id);

  if (nutritionItem === undefined) {
    throw new Error('Error nutritionItem with id ' + id + ' not found');
  }

  return nutritionItem;
}

export async function deleteNutritionItem(nutritionItem: NutritionItem) {
  await deleteEntry(nutritionItem, TABLES.NUTRITION_ITEM);
  invalidateTableCache(TABLES.NUTRITION_ITEM);
}

export async function addNutritionItem(nutritionItem: ExcludeId<NutritionItem>): Promise<NutritionItem> {
  const createdNutritionItem = await addEntryFillKey<NutritionItem>(nutritionItem, TABLES.NUTRITION_ITEM);
  invalidateTableCache(TABLES.NUTRITION_ITEM);
  return createdNutritionItem;
}

export async function editNutritionItem(nutritionItem: NutritionItem) {
  await editEntry<NutritionItem>(nutritionItem, TABLES.NUTRITION_ITEM);
  invalidateTableCache(TABLES.NUTRITION_ITEM);
}