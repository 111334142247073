// returns an integer of the current day from 1970. Resets every day at 02:00.
// (I think it only works for GMT+1, but I am lazy so I won't check or fix it)
export function getCurrentDayIndex(): number {
  const ts = Date.now() - 1000 * 60 * 60;
  const day = ts / 1000 / 60 / 60 / 24
  return Math.floor(day);
}

export function monthNumberToStr(monthNum: number) {
  return ["January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"][monthNum - 1]
}