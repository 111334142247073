import { IconButton, Typography } from "@mui/material"
import DeleteIcon from '@mui/icons-material/Delete';
import { red } from "@mui/material/colors";
import { Fragment, useState } from "react";
import DeleteConfirmDialog from "../dialogs/DeleteConfirmDialog";

interface Props {
  onConfirm: () => any,
  noPadding?: boolean,
}

export default (props: Props) => {
  const [open, setOpen] = useState(false);
  const noPaddingClass = props.noPadding === true ? 'no-padding' : '';

  return (
    <Fragment>
      <IconButton edge="end" aria-label="delete" onClick={() => setOpen(true)} className={noPaddingClass}>
        <DeleteIcon sx={{ color: red[900] }} />
      </IconButton>
      <DeleteConfirmDialog
        onConfirm={props.onConfirm}
        open={open}
        setClose={() => setOpen(false)} />

    </Fragment>
  )
}