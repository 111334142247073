import { ExerciseSet } from '../logic/entities/ExerciseSet';
import { Box, Divider, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Props {
  set: ExerciseSet;
  children?: any;
}

export const WorkoutSet = (props: Props) => {
  const set = props.set;
  const repInfo = set.weight + " kg @ " + set.reps + " reps";
  return <Box key={Math.random()}>
    <Divider />
    <ListItem disablePadding secondaryAction={<ArrowForwardIosIcon />}>
      <ListItemButton>
        <ListItemText primary={repInfo} secondary={set.exercise.name} />
      </ListItemButton>
    </ListItem>
    <Divider />
  </Box>
}