import React from 'react';
import { ScreenWrapper } from '../ScreenWrapper';

class SettingsScreen extends React.Component {

    render() {
        return <ScreenWrapper title='Settings screen!'>
        </ScreenWrapper>
    }
}

export default SettingsScreen;