import { FormEvent } from "react";

interface Props {
  onSubmit: (content: any) => void
  children?: any;
}

export default (props: Props) => {

  function submit(event: FormEvent<HTMLFormElement>) {
    const data = new FormData(event.target as HTMLFormElement);
    const dataObject = Object.fromEntries(data.entries())
    event.preventDefault();

    props.onSubmit(dataObject);
  }

  return <form onSubmit={submit}>
    {props.children}
  </form>
};