import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from "@mui/material";
import Form from "../general/Form";

interface Props {
  onConfirm: () => void,
  open: boolean,
  setClose: () => void,
}

export default (props: Props) => {

  function submitAddItemForm() {
    props.setClose();
    props.onConfirm();
  }

  return (
    <Dialog open={props.open} onClose={() => props.setClose()}>
      <DialogTitle>Delete confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setClose()}>Cancel</Button>
        <Button color="error" onClick={submitAddItemForm}>Delete</Button>
      </DialogActions>
    </Dialog>
  )
}