import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material';
import React, { useState } from 'react';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import SettingsIcon from '@mui/icons-material/Settings';
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const MainBottomNavigation = () => {

  const navigate = useNavigate();
  const [currentNavigation, setCurrentNavigation] = useState('/');

  return <Box>
    <Box sx={{ height: '56px' }}></Box>
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
      elevation={1}>
      <BottomNavigation
        showLabels
        style={{ height: 'calc(56px + env(safe-area-inset-bottom, 0px))', paddingBottom: 'env(safe-area-inset-bottom, 0px)' }}
        value={currentNavigation}
        onChange={(event, newUrl) => {
          setCurrentNavigation(newUrl)
          navigate(newUrl);
        }}
      >
        <BottomNavigationAction
          value="/workout"
          label="Workout"
          icon={<FitnessCenterIcon />}
        />
        <BottomNavigationAction
          value="/diet"
          label="Diet"
          icon={<RestaurantIcon />}
        />
        <BottomNavigationAction
          value="/settings"
          label="Settings"
          icon={<SettingsIcon />}
        />
      </BottomNavigation>
    </Paper>
  </Box>
}

// export default MainBottomNavigation;