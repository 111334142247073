import { ExcludeId } from './entities/ExcludeId';
import { Exercise } from './entities/Exercise';
import { Identifier } from './entities/Identifier';
import { NutritionItem } from './entities/NutritionItem';
import { WorkoutSchema } from './entities/WorkoutList';

let workouts: Array<any> | undefined = undefined;
const anyWindow = window as any;
const indexedDB = (anyWindow.indexedDB || anyWindow.mozIndexedDB || anyWindow.webkitIndexedDB || anyWindow.msIndexedDB || anyWindow.shimIndexedDB) as IDBFactory;
console.log(' anyWindow', anyWindow)
anyWindow.exportDB = async () => {
  const unlockDb = await getAndLockDb();

  await waitForReadyState(dbConnection);

  const dbObject = {} as any;
  for (const tableName of Object.values(TABLES)) {

    const conn = await waitForReadyState<IDBDatabase>(dbConnection);
    const transaction = conn.transaction(tableName, 'readwrite');
    const table = transaction.objectStore(tableName);
    const elementsInTable = await waitForReadyState<any[]>(table.getAll());
    console.log('table.getAll', elementsInTable)
    dbObject[tableName] = elementsInTable;
  }

  unlockDb();

  console.log(JSON.stringify(dbObject));

  return dbObject;
}
anyWindow.importDB = async (dbObject: any) => {
  const unlockDb = await getAndLockDb();

  await waitForReadyState(dbConnection);

  for (const [tableName, tableContent] of Object.entries(dbObject)) {

    const conn = await waitForReadyState<IDBDatabase>(dbConnection);
    const transaction = conn.transaction(tableName, 'readwrite');
    const table = transaction.objectStore(tableName);
    await waitForReadyState<any[]>(table.clear());

    for (const item of tableContent as any) {
      table.add(item);
    }
  }

  unlockDb();
}

let dbConnection = indexedDB.open('fitness-tracker', 8);

const standardExercises = [{
  name: 'Bench Press'
},
{
  name: 'Percotal'
},
{
  name: 'Dumbell Press'
}];

const standardNutritionItems: ExcludeId<NutritionItem>[] = [
  {
    name: 'Proteine shake',
    description: '200ml, 1 scoop',
    proteine: 32,
    category: 'Drinks',
  },
  {
    name: 'Proteine shake ',
    description: '250ml, 2 scoops',
    proteine: 37,
    category: 'Drinks',
  },
  {
    name: 'Proteine bar',
    proteine: 25,
    category: 'Snacks',
  },
  {
    name: 'Cashew nuts',
    proteine: 5,
    description: 'Handfull of cashew nuts',
    category: 'Snacks',
  },
  {
    name: 'HiPro Proteine drink',
    description: 'From the supermarket',
    proteine: 25,
    category: 'Drinks',
  },
  {
    name: 'Melkuni Proteine drink',
    description: 'From the supermarket',
    proteine: 20,
    category: 'Drinks',
  },
  {
    name: 'Yoghurt with Granola',
    description: '250g yoghurt with 35g Granola',
    proteine: 29,
    category: 'Lunch/Breakfast',
  },
  {
    name: 'Milk with Granola',
    description: '220ml milk with 65 Granola',
    proteine: 14,
    category: 'Lunch/Breakfast',
  },
];

let cacheTable: any = {};
let cacheKeyTableLink: any = {}

export const TABLES = {
  WORKOUT_SCHEMA: 'workoutList',
  EXERCISES: 'exercises',
  PERFORMED_WORKOUTS: 'performedWorkout',
  NUTRITION_ITEM: 'nutritionItem',
  CONSUMED_ITEMS: 'consumedItems',
};

dbConnection.onsuccess = (event) => {
  console.log('on success');
}

async function init() {
  const unlockDb = await getAndLockDb();

  await waitForReadyState(dbConnection);

  unlockDb();
}

// Create the schema
dbConnection.onupgradeneeded = async function (event: IDBVersionChangeEvent) {
  console.log('upgrading');
  var db = dbConnection.result;
  // const releaseDb = await getAndLockDb();

  console.log('Running onupgradeneeded. Old version:', event.oldVersion, 'new version:', event.newVersion);

  if (event.oldVersion < 8) {
    console.log('Running all of the migrations');
    Object.values(TABLES).forEach(tableName => deleteStore(db, tableName));

    var workoutListTable = db.createObjectStore(TABLES.WORKOUT_SCHEMA, { keyPath: 'id', autoIncrement: true });

    var exercisesTable = db.createObjectStore(TABLES.EXERCISES, { keyPath: 'id', autoIncrement: true });

    var performedWorkoutTable = db.createObjectStore(TABLES.PERFORMED_WORKOUTS, { keyPath: 'id', autoIncrement: true });
    performedWorkoutTable.createIndex('isCompleted', 'isCompleted', { unique: false });

    const nutritionItemTable = db.createObjectStore(TABLES.NUTRITION_ITEM, { keyPath: 'id', autoIncrement: true });

    const cunsumedItemsTable = db.createObjectStore(TABLES.CONSUMED_ITEMS, { keyPath: 'id', autoIncrement: true });

    standardExercises.forEach((ex) => {
      exercisesTable.add(ex)
    });

    standardNutritionItems.forEach((item) => {
      nutritionItemTable.add(item)
    });
  }

  // releaseDb();
  cacheTable = {};
  cacheKeyTableLink = {};
  // if (event.oldVersion < 5) {
  //   const nutritionItemTable = db.createObjectStore(TABLES.NUTRITION_ITEM, { keyPath: 'id', autoIncrement: true });
  // }

  // var transaction = (event.target as any)?.transaction as IDBTransaction;

  // if (event.oldVersion <= 2) {
  //   const table = transaction.objectStore(TABLES.PERFORMED_WORKOUTS);
  //   table.createIndex('isCompleted', 'isCompleted', { unique: false });
  // }
};

const deleteStore = (db: IDBDatabase, storeName: string) => {
  try {
    db.deleteObjectStore(storeName);
  } catch (err: any) {
    if (err.name !== 'NotFoundError') throw err;
  }
};

const waitForReadyState = async <T>(request: IDBRequest): Promise<T> => {
  return new Promise((acc, rej) => {
    if (request.readyState === 'done') {
      acc(request.result);
      return;
    }

    request.onsuccess = (event) => {
      acc(request.result);
    };

    request.onerror = (event) => {
      rej(event);
    }
  });
}

export async function cacheEntry<T>(key: string, tableName: string, getValue: () => Promise<T>): Promise<T> {
  const releaseDb = await getAndLockDb();

  cacheKeyTableLink[key] = tableName;
  if (!cacheTable[key]) {
    cacheTable[key] = await getValue();
  }
  const cachedResult = cacheTable[key] as T;

  releaseDb();

  return cachedResult;
}

export function invalidateCacheKey(cacheKey: string) {
  delete cacheTable[cacheKey];
}

export function invalidateTableCache(tableName: string) {
  Object.entries(cacheKeyTableLink)
    .filter(([_, value]) => value === tableName)
    .map(([key, _]) => key)
    .forEach(invalidateCacheKey);
}

export async function cacheQuery<T>(queryKey: string, tableName: string): Promise<T[]> {

  const queryResult = cacheEntry<T[]>(queryKey, tableName, async () => {
    const conn = await waitForReadyState<IDBDatabase>(dbConnection);
    const transaction = conn.transaction(tableName, 'readwrite');
    const table = transaction.objectStore(tableName);
    const elementsInTable = await waitForReadyState<any[]>(table.getAll());
    return elementsInTable;
  });

  return queryResult;
}

export async function deleteEntry(entry: Identifier, tableName: string) {
  const releaseDb = await getAndLockDb();

  const conn = await waitForReadyState<IDBDatabase>(dbConnection);
  const transaction = conn.transaction(tableName, 'readwrite');
  const table = transaction.objectStore(tableName);

  await waitForReadyState<IDBValidKey>(table.delete(entry.id));

  releaseDb();
}

/**
 * @deprecated use addEntryFillKey
 */
export async function addEntry<T>(entry: T, tableName: string): Promise<IDBValidKey> {
  const releaseDb = await getAndLockDb();

  const conn = await waitForReadyState<IDBDatabase>(dbConnection);
  const transaction = conn.transaction(tableName, 'readwrite');
  const table = transaction.objectStore(tableName);

  const IDBValidKey = await waitForReadyState<IDBValidKey>(table.add(entry));

  releaseDb();

  return IDBValidKey;
}

export async function addEntryFillKey<T extends Identifier>(entry: any, tableName: string): Promise<T> {
  const key = await addEntry(entry, tableName);
  const newItem: T = {
    ...entry,
    id: key as number
  };

  return newItem;
}

export async function editEntry<T extends Identifier>(entry: T, tableName: string): Promise<void> {
  const releaseDb = await getAndLockDb();

  const conn = await waitForReadyState<IDBDatabase>(dbConnection);
  const transaction = conn.transaction(tableName, 'readwrite');
  const table = transaction.objectStore(tableName);

  await waitForReadyState<IDBValidKey>(table.put(entry));

  releaseDb();
}

const dbLockCallbacks: (() => void)[] = [];

function getAndLockDb(): Promise<() => void> {
  function releaseDb() {
    dbLockCallbacks.shift();
    if (dbLockCallbacks.length >= 1) {
      dbLockCallbacks[0]();
    }
  }

  return new Promise((acc, _) => {
    dbLockCallbacks.push(() => {
      acc(releaseDb)
    });

    if (dbLockCallbacks.length == 1) {
      dbLockCallbacks[0]();
    }
  });
}

init();


// export const getexercise = async (id?: number | string): Promise<Exercise | undefined> => {
//   console.log('getexercisegetexercisegetexercise, ' + await getexercises());

//   return (await getexercises())
//     .filter(
//       exercise => { return exercise.id == id }
//     )[0]

// }

// export const getexercises = async (): Promise<Exercise[]> => {

//   return cacheEntry<Exercise[]>('exercises-all', async () => {
//     const conn = await waitForReadyState<IDBDatabase>(dbConnection);
//     const transaction = conn.transaction('exercises', 'readwrite');
//     const workoutListTable = transaction.objectStore('exercises');

//     const exercises = await waitForReadyState<any[]>(workoutListTable.getAll());

//     return exercises;

//   });
// }

// export const getWorkouts = async (): Promise<WorkoutSchema[]> => {

//   return cacheEntry<WorkoutSchema[]>('workoutList-all', async () => {

//     const conn = await waitForReadyState<IDBDatabase>(dbConnection);
//     const transaction = conn.transaction('workoutList', 'readwrite');
//     const workoutListTable = transaction.objectStore('workoutList');

//     const workoutLists = await waitForReadyState<any[]>(workoutListTable.getAll());

//     return workoutLists;
//   });
// }