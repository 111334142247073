import React from 'react';
import './index.css';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import HomeScreen from './screens/WorkoutScreen';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DietScreen from './screens/DietScreen';
import SettingsScreen from './screens/SettingsScreen';
import { MainBottomNavigation } from './components/navigation/MainBottomNavigation';
import ExerciseScreen from './screens/workout/ExerciseScreen';
import { Box } from '@mui/material';

const darkTheme = createTheme({
  components: {
    MuiAppBar: {

    }
  },
  palette: {
    mode: 'dark',
  },
});

class App extends React.Component {

  render() {
    return <div>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />

        <BrowserRouter>
          <Box>
            <Routes>

              <Route path="/" element={<Navigate to="/workout" />} />

              <Route path="/workout" element={<HomeScreen />} />
              <Route path="/workout/execute" element={<ExerciseScreen />} />
              <Route path="/workout/execute/:workoutId" element={<ExerciseScreen />} />
              {/* <Route path="/exercise/execute/:exerciseId" element={<ExerciseScreen />} /> */}

              <Route path="/diet" element={<DietScreen />} />

              <Route path="/settings" element={<SettingsScreen />} />
            </Routes>
          </Box>

          <MainBottomNavigation />
        </BrowserRouter>

      </ThemeProvider>
    </div>
  }
}

export default App;