import { TABLES, addEntryFillKey, cacheQuery, deleteEntry, invalidateTableCache } from "./Database";
import { ConsumedItem } from "./entities/ConsumedItem";
import { ExcludeId } from "./entities/ExcludeId";

export async function getTodaysConsumedItems(day: number): Promise<ConsumedItem[]> {
  const allConsumedItems = await cacheQuery<ConsumedItem>('getAllConsumedItems', TABLES.CONSUMED_ITEMS);

  const todaysConsumedItems = allConsumedItems
    .filter(consumedItem => consumedItem.day == day)
    .sort((consumedItem1, consumedItem2) => consumedItem1.timestamp - consumedItem2.timestamp);

  return todaysConsumedItems
}

export async function deleteConsumedItem(consumedItem: ConsumedItem) {
  await deleteEntry(consumedItem, TABLES.CONSUMED_ITEMS);
  invalidateTableCache(TABLES.CONSUMED_ITEMS);
}

export async function addConsumedItem(consumedItem: ExcludeId<ConsumedItem>): Promise<ConsumedItem> {
  const createdConsumedItem = await addEntryFillKey<ConsumedItem>(consumedItem, TABLES.CONSUMED_ITEMS);
  invalidateTableCache(TABLES.CONSUMED_ITEMS);
  return createdConsumedItem;
}
