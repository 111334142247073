import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material"
import { ForwardedRef, Fragment, forwardRef, useEffect, useState } from "react"
import DeleteButton from "./general/DeleteButton"
import { ConsumedItem } from "../logic/entities/ConsumedItem";
import { addConsumedItem, deleteConsumedItem, getTodaysConsumedItems } from "../logic/ConsumedItemRepository";
import { getCurrentDayIndex, monthNumberToStr } from "../logic/Utils";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import QuickAddConsumationDialog from "./dialogs/QuickAddConsumationDialog";
import { ExcludeId } from "../logic/entities/ExcludeId";

interface Props {
  consumedItems: ConsumedItem[];
  addedQuickItem: (item: ConsumedItem) => void;
  day: number;
  refresh: () => void;
  setDay: (day: number) => void;
}

function getDateRenderStr(daysInPast: number) {
  if (daysInPast === 0) {
    return "today";
  }

  if (daysInPast === 1) {
    return "yesterday";
  }

  const date = new Date(Date.now() - daysInPast * 1000 * 60 * 60 * 24);
  return `${date.getDate()} ${monthNumberToStr(date.getMonth() + 1).slice(0, 3)}`;
}

function getDayName(daysFromToday: number) {
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun',];
  const date = new Date((Date.now() - 1000 * 60 * 60 * 2) - (daysFromToday * 1000 * 60 * 60 * 24));
  return days[date.getDay() - 1];
}

function timestampToStr(timestamp: number): string {
  const dateTime = new Date(timestamp);
  const hours = dateTime.getHours();
  const minutes = dateTime.getMinutes();

  return `${(hours < 10 ? '0' + hours : hours)}:` +
    `${(minutes < 10 ? '0' + minutes : minutes)}`;
}

export default forwardRef((props: Props, ref: ForwardedRef<any>) => {

  const [openQuickAdd, setOpenQuickAdd] = useState(false);

  const consumedItems = props.consumedItems;
  const totalProteine = consumedItems.reduce(
    (totalValue, consumedItem) => parseInt(consumedItem.nutritionItem.proteine as any) + totalValue,
    0,
  );

  const totalCalories = consumedItems.reduce(
    (totalValue, consumedItem) => {
      const calories = consumedItem.nutritionItem.calories as any;
      if (calories === undefined) return totalValue;
      return parseInt(calories) + totalValue
    },
    0,
  );
  const remainingProteine = 115 - totalProteine; // TODO, make 115 dynamic

  async function deleteItem(consumedItem: ConsumedItem) {
    await deleteConsumedItem(consumedItem);
    props.refresh();
  }

  const dayRenderMinusToday = getCurrentDayIndex() - props.day;
  let dateRenderStr = getDateRenderStr(dayRenderMinusToday)
  console.log(dayRenderMinusToday);
  const dayName = getDayName(dayRenderMinusToday);

  async function quickAddItem(consumedItem: ExcludeId<ConsumedItem>) {
    props.addedQuickItem(
      await addConsumedItem(consumedItem)
    );
  }

  return (
    <Fragment>
      <Box>
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid container spacing={1} item xs={8}>
            <Grid item xs="auto" sx={{ textAlign: 'end' }} className={'no-top-left-padding'}>
              <IconButton onClick={() => props.setDay(props.day - 1)} size="small">
                <ArrowLeftIcon fontSize="large" />
              </IconButton>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: '50px' }} className={'no-top-left-padding'}>
              {dateRenderStr} ({dayName})
            </Grid>
            <Grid item xs className={'no-top-left-padding'}>
              <IconButton onClick={() => props.setDay(props.day + 1)} disabled={dayRenderMinusToday <= 0} size="small">
                <ArrowRightIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }} className={'no-top-left-padding'}>
            <Button size="small" variant="contained" onClick={() => setOpenQuickAdd(true)}>Quick add</Button>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={1} sx={{ mb: 2 }}>
        {consumedItems.length ? (
          <Fragment>
            {consumedItems.map(consumedItem =>
              <Fragment key={consumedItem.id}>
                <Grid item xs="auto">
                  <Typography>{timestampToStr(consumedItem.timestamp)}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{consumedItem.nutritionItem.name}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Stack>
                    <Typography variant="caption">
                      {consumedItem.nutritionItem.proteine &&
                        Math.round(consumedItem.nutritionItem.proteine) + 'g'}
                      &nbsp;
                      {consumedItem.nutritionItem.calories &&
                        Math.round(consumedItem.nutritionItem.calories) + 'kcal'}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs="auto">
                  <DeleteButton onConfirm={() => deleteItem(consumedItem)} noPadding></DeleteButton>
                </Grid>
              </Fragment>)}
            <Box sx={{ ml: 1, mt: 1 }}>
              <b>Total: {totalProteine} gram,
                {remainingProteine > 0 ? ` ${remainingProteine} left` : ` +${-remainingProteine} extra`}
                &nbsp;|&nbsp;{totalCalories} kcal
              </b>
            </Box>
          </Fragment>
        ) : (
          <Grid item xs={12}>
            <h4>No items</h4>
          </Grid>
        )}
      </Grid>

      <QuickAddConsumationDialog
        onSubmit={quickAddItem} open={openQuickAdd}
        setClose={() => setOpenQuickAdd(false)} />
    </Fragment >
  )
});