import { TABLES, addEntry, cacheQuery } from "./Database";
import { ExcludeId } from "./entities/ExcludeId";
import { Exercise } from "./entities/Exercise"
import { PerformedWorkout } from "./entities/PerformedWorkout";
import { WorkoutSchema } from "./entities/WorkoutList";

export async function getAllExercises(): Promise<Exercise[]> {
  return await cacheQuery<Exercise>('getAllExercises', TABLES.EXERCISES);
}

export async function getAllWorkoutSchemas(): Promise<WorkoutSchema[]> {
  return await cacheQuery<WorkoutSchema>('getAllWorkoutSchemas', TABLES.WORKOUT_SCHEMA);
}

export async function addWorkoutSchema(Workout: WorkoutSchema): Promise<WorkoutSchema> {
  const key: IDBValidKey = await addEntry<WorkoutSchema>(Workout, TABLES.WORKOUT_SCHEMA);
  Workout.id = key as number;
  return Workout;
}

type PerformedWorkoutNoKey = ExcludeId<PerformedWorkout>;

export async function addPerformedWorkout(Workout: PerformedWorkoutNoKey): Promise<PerformedWorkout> {
  const key: IDBValidKey = await addEntry<PerformedWorkoutNoKey>(Workout, TABLES.PERFORMED_WORKOUTS);
  const performedWorkout: PerformedWorkout = {
    ...Workout,
    id: key as number
  };
  return performedWorkout;
}

// export async function getLastNotCompletedPerformedWorkout(): Promise<PerformedWorkout|undefined> {

// }