import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import Form from "../general/Form";
import { NutritionItem } from "../../logic/entities/NutritionItem";
import { ExcludeId } from "../../logic/entities/ExcludeId";
import { ConsumedItem } from "../../logic/entities/ConsumedItem";

interface Props {
  item: NutritionItem | undefined,
  onSubmit: (content: NutritionItem | any) => void,
  open: boolean,
  setClose: () => void,
}

export default (props: Props) => {

  function submitAddItemForm(formData: any) {
    const multiplier = parseFloat(formData.multiplier);
    let nutritionalItem = props.item!;

    if (multiplier === 1.0) {

      props.setClose();
      props.onSubmit(nutritionalItem);
      return;
    }

    const newItem: ExcludeId<NutritionItem> = {
      ...nutritionalItem!,
      proteine: nutritionalItem.proteine * multiplier,
    };
    delete (newItem as any).id;

    if (nutritionalItem.calories) {
      newItem.calories = nutritionalItem.calories * multiplier
    }

    console.log("🚀 ~ nutritionalItem:", nutritionalItem)
    props.setClose();
    props.onSubmit(newItem);
  }

  return (
    <Dialog open={props.open} onClose={() => props.setClose()}>
      <FormControl error={true} required>
        <Form onSubmit={submitAddItemForm}>
          <DialogTitle>Quick add</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Edit portion size of {props.item?.name}.
            </DialogContentText>

            <TextField
              required
              defaultValue={"1"}
              margin="dense"
              label="multiplier"
              type="number"
              fullWidth
              variant="standard"
              name="multiplier"
              inputProps={{ inputMode: "decimal", step: ".01" }}
            />

          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setClose()}>Cancel</Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </Form>
      </FormControl>
    </Dialog>
  )
}