import { Fragment, useEffect, useState } from 'react';
import { ScreenWrapper } from '../ScreenWrapper';
import { Box, Button, Divider, Grid } from '@mui/material';
import { NutritionItem } from '../logic/entities/NutritionItem';
import { addNutritionItem, deleteNutritionItem, getAllNutritionItems } from '../logic/NutritionRepository';
import { addConsumedItem, getTodaysConsumedItems } from '../logic/ConsumedItemRepository';
import EditNutritionItemDialog from '../components/dialogs/AddNutritionItemDialog';
import { ExcludeId } from '../logic/entities/ExcludeId';
import ConsumationTable from '../components/ConsumationTable';
import { getCurrentDayIndex } from '../logic/Utils';
import { ConsumedItem } from '../logic/entities/ConsumedItem';
import H3 from '../components/general/H3';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandableGrid from '../components/ExpandableGrid';

import NutritionItemRow from '../components/NutritionItemRow';
import EatItemDialog from '../components/dialogs/EatItemDialog';

interface NutritionItemMap {
  [key: string]: NutritionItem[];
}

export default () => {

  const [openNutritionDialog, setOpenNutritionDialog] = useState(false);
  const [nutritionalItemToAdd, setNutritionalItemToAdd] = useState<NutritionItem | undefined>(undefined);
  const [nutritionItems, setNutritionItems] = useState<NutritionItem[]>([]);
  const [nutritionItemsMap, setNutritionItemsMap] = useState<NutritionItemMap>({});
  const [consumedItems, setConsumedItems] = useState<ConsumedItem[]>([]);
  const [todayIndex, setTodayIndex] = useState(getCurrentDayIndex());

  useEffect(() => { fetchConsumedItems() }, [todayIndex]);
  useEffect(() => { fetchNutritionItems() }, []);

  useEffect(() => {
    const onScroll = (event: any) => {
      setTodayIndex(getCurrentDayIndex());
      fetchConsumedItems();
      setNutritionItemsMap({});
      fetchNutritionItems();
      console.info("scrolling", event)
    };

    window.addEventListener('focus', onScroll);

    return () => {
      window.removeEventListener('focus', onScroll);
    }
  }, []);

  async function fetchConsumedItems() {
    const consumedItems = await getTodaysConsumedItems(todayIndex);
    setConsumedItems(consumedItems);
  }

  async function fetchNutritionItems() {
    const nutritionItems = await getAllNutritionItems()
    setNutritionItems(nutritionItems);

    const categoryMap: NutritionItemMap = {};
    nutritionItems.forEach(nutritionItem => {
      const category = nutritionItem.category ? nutritionItem.category : 'Other';
      categoryMap[category] = categoryMap[category] ?? [];
      categoryMap[category].push(nutritionItem);
    });
    setNutritionItemsMap(categoryMap);
  }

  async function submitAddItemForm(nutritionItemToAdd: NutritionItem | ExcludeId<NutritionItem>) {
    await addNutritionItem(nutritionItemToAdd)
    fetchNutritionItems();
  }

  async function submitAddConsumedItemForm(ajustedNutritionalItem: NutritionItem) {
    console.log("🚀 ~ ajustedNutritionalItem:", ajustedNutritionalItem)

    const consumedItemToAdd = {
      day: getCurrentDayIndex(),
      nutritionItem: ajustedNutritionalItem,
      timestamp: Date.now(),
    };

    // Failsafe for storage
    if (consumedItems.length === 0) {
      (window as any).exportDB()
        .then((dbObj: any) => {

          const rawDB = JSON.stringify(dbObj);
          console.log(rawDB)

          fetch('/store.php', { method: "POST", body: rawDB });
          console.log('Wrote', rawDB.length / 1024, 'kb');
        });
    }

    await addConsumedItem(consumedItemToAdd)
    fetchConsumedItems();
  }

  async function handleAddConsumedItem(item: NutritionItem) {
    setNutritionalItemToAdd(item);
  }

  return <ScreenWrapper title="Your diet">
    <ConsumationTable
      addedQuickItem={fetchConsumedItems}
      setDay={setTodayIndex}
      day={todayIndex}
      consumedItems={consumedItems}
      refresh={fetchConsumedItems} />

    <Divider />

    <Box sx={{ mt: 2 }}>

      <Grid container spacing={1}>
        <Grid item xs={7}>
          <h2>Choose food</h2>
        </Grid>
        <Grid item xs={5} sx={{ textAlign: "right" }}>
          <Button size="small" variant="contained" onClick={() => setOpenNutritionDialog(true)}>Create new</Button>
        </Grid>

        {Object.entries(nutritionItemsMap).map(([category, nutritionItems], index) =>
          <Fragment key={category}>
            <ExpandableGrid
              defaultOpen={false}
              content={(isOpen) =>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <H3>
                    {category}
                  </H3>
                  {isOpen ? <ExpandLess fontSize="large" /> : <ExpandMore fontSize="large" />}
                </Box>
              }>

              {nutritionItems.map(item =>
                <NutritionItemRow
                  refresh={fetchNutritionItems}
                  key={item.id}
                  item={item}
                  clickedAdd={() => handleAddConsumedItem(item)} />
              )}
            </ExpandableGrid>
          </Fragment>
        )}
      </Grid>
    </Box>

    <EatItemDialog
      item={nutritionalItemToAdd}
      onSubmit={submitAddConsumedItemForm}
      open={nutritionalItemToAdd !== undefined}
      setClose={() => setNutritionalItemToAdd(undefined)} />

    <EditNutritionItemDialog
      onSubmit={submitAddItemForm}
      open={openNutritionDialog}
      setClose={() => setOpenNutritionDialog(false)} />

  </ScreenWrapper>
};
