import { Box } from "@mui/material"
import { TopAppBar } from "./components/navigation/TopAppBar";

interface Props {
  backText?: string;
  title?: string;
  children?: any;
}

function renderTopNavigation(props: Props) {
  if (props.backText) {
    return <TopAppBar title={props.title} backText={props.backText}></TopAppBar>

  }
  if (props.title) {
    return <h1>{props.title}</h1>;
  }
  return <h1>Error: title or backtext is required</h1>;
}

export const ScreenWrapper = (props: Props) => {
  return <Box sx={{ p: 2, maxHeight: '9%' }}>
    <Box>
      {renderTopNavigation(props)}
    </Box>
    {props.children}
  </Box>
}