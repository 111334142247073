import { Fragment, useState } from "react";
import { NutritionItem } from "../logic/entities/NutritionItem";
import { Grid, IconButton, Stack, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AddNutritionItemDialog from "./dialogs/AddNutritionItemDialog";
import { editNutritionItem } from "../logic/NutritionRepository";

interface Props {
  item: NutritionItem;
  clickedAdd: (item: NutritionItem) => {};
  refresh: () => void;
}

export default (props: Props) => {

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const item = props.item;

  async function submitEditNutritionItem(editedItem: NutritionItem) {
    await editNutritionItem(editedItem);
    props.refresh();
  }

  return <Fragment>
    <Grid item xs={12} container sx={{ ml: 1, mb: 1 }}>
      <Grid item xs={7}>
        <Typography>{item.name}</Typography>
        <Typography variant='caption'>{item.description}</Typography>
      </Grid>
      <Grid item xs sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack>
          <Typography variant="caption" >{item.proteine}g</Typography>
          {item.calories && <Typography variant="caption" >{item.calories}kcal</Typography>}
        </Stack>
      </Grid>
      <Grid item xs="auto">
        <IconButton onClick={() => props.clickedAdd(item)} color='primary' >
          <AddIcon fontSize='large'></AddIcon>
        </IconButton>
        <IconButton onClick={() => setOpenEditDialog(true)}>
          <EditIcon></EditIcon>
        </IconButton>
      </Grid>
    </Grid>

    <AddNutritionItemDialog
      onDelete={props.refresh}
      editItem={item}
      onSubmit={editedItem => submitEditNutritionItem(editedItem as NutritionItem)}
      open={openEditDialog}
      setClose={() => setOpenEditDialog(false)}
    />
  </Fragment >
}